import React from "react";
import NewsLetter from "../global/NewsLetter";
import SubHeader from "../global/SubHeader";
import { SUB_HEADINGS } from "../../constants";
import AakashgangaHeader from "../global/HeaderComponent";
import AakashgangaFooter from "../global/FooterComponent";
import { Helmet } from 'react-helmet-async';
const About = () => {
  return (
    <>
     <Helmet>
          <title>About | Aakashganga: Open access Publishing platform</title>
          <meta name="description" content="Publish your research with Aakashganga's peer-reviewed open access platform. Discover how our open access platform empowers researchers through cost-effective publishing and an efficient process." />
          <meta property="og:title" content="About | Aakashganga: Open access Publishing platform" data-react-helmet="true" />
          <meta property="og:description" content="Publish your research with Aakashganga's peer-reviewed open access platform. Discover how our open access platform empowers researchers through cost-effective publishing and an efficient process." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/about-us" data-react-helmet="true" />


          <link rel="canonical" href="https://www.aakashgangaopen.in/about-us" data-react-helmet="true" />
          <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="tagbody">
        <AakashgangaHeader />
        <h1 className="seo-related-tag">
          About Aakashganga
        </h1>
        <main className="main">
          <SubHeader title={SUB_HEADINGS.ABOUT_AAKASHGANGA} />
          <div className="content-area aboutAakash">
          {/* <div className="aboutAakash"> */}
          <div className="container">
           <p> For years researchers across the globe have been facing challenges
            in getting published. The biggest challenge with subscription
            journals is the lack of responses, lengthy publishing timelines,
            multiple levels of unjustified scrutiny and overall, an experience
            that left a researcher frustrated.</p>
            <p>
              While Open Access addressed many of these challenges the high
              Article Processing Charge coupled with monopolistic publishing
              policies haven’t really addressed the vast masses of frustrated
              researchers. It is common knowledge that certain types of research
              and researchers publish with ease while others struggle.
            </p>
            <p>
              In the world of interdisciplinary and multidisciplinary research
              the advent of Mega journals in the early part of this century was
              a welcome change. They eased the process of publishing
              contributions to multiple disciplines with ease. But over the
              years their costs have reached a level that a handful in the
              developing world can afford.
            </p>
             
            <h2 class="large-title">Presenting Aakashganga</h2>
            <p>
              Aakashganga is India’s first Open Access Journal Publishing and
              Hosting platform geared to address challenges faced by researchers
              mostly outside of the Global West.
            </p>
            
              <b>Aakashganga has three broad offerings:</b>
              <br />
              <ol className="numListStatic">
                <li>Seven Mega Journals to publish Interdisciplinary broad scope research – Local and Global </li>
                <li>An Open Access hosting platform to launch journals of societies, universities and institutes </li> 
                <li>Publish Conference Papers in Mega Journals with or without a Landing Page </li> 
              </ol>
               
              <p>
                The features of the platform are:
                <br />
              </p>
              <ol className="numListStatic">
                <li><b>Verification of submitting academic/practitioner</b> –
                only verified users can make submissions.</li>
                <li><b>
                  {" "}
                  Automated Spell Check, Grammar Check, Syntax Check, Profanity
                  Check, Plagiarism Check
                </b>{" "}
                – no human resources needed to process, the system is built to
                do this automatically saving precious time.</li>
                <li><b> Automated Citation Generator</b> – no complicated Citation
                formatting.</li>
                <li><b>
                  {" "}
                  Automated Editor/Editorial Board/Editor-in-chief functions
                </b>{" "}
                – decide with a click of a button if an article is worth sending
                for Peer Review or not.</li>
                <li><b>Automated and Anonymous Peer Reviewer selector</b> – you
                can opt for choosing from our vast database of Peer Reviewers or
                assign an article to a specialist.</li>
                <li><b>Automated Post Publishing Peer Review</b> – every article
                published on our platform is open to review by peers post
                publishing.</li>
                <li><b>Automated Open Access Publishing</b> – every article
                published is open access.</li>
                <li> <b>Article Publishing Fees management</b> – choose between
                author paying or institution subsidizing the fees.</li>
                <li><b>Affordable APC</b> – some of the lowest rates in the world.</li>
                <li><b>Articles published when ready</b> – do away with lengthy
                wait times for issues to publish. Articles can be published in
                as little as 30 days depending on the type of peer review
                selected.</li>
                <li><b>Marketing support</b> - for every article.</li>
                <li><b>CrossRef authenticated DOI</b> - issued for every article.</li>
                <li><b>ISSN awarded to each journal</b> - subject to ISSN issuing
                guidelines.</li>
              </ol>
             
               
         
            <p>
              Whether you are an institution looking to publish your journals or
              a researcher looking to publish your research, Aakashganga is here
              to provide answers.
            </p>
            <p>
              Aakashganga is part of Vikramshila Education; a mission to
              democratize publishing, make it affordable and put researchers
              first.
            </p>
            <p>
              Write to us at{" "}
              <a href="mailto:contactus@vikramshilaedu.in">
                contactus@vikramshilaedu.in
              </a>
            </p>
            <p className="joinMiss" ><a href="/registration" target="_blank"><b><u>Join the Mission</u></b></a></p>
          </div>
          </div>
        </main>

        <NewsLetter />
        <AakashgangaFooter />
      </div>
    </>
  );
};

export default About;
