import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import AffordableApc from "../components/articleProcessingCharges/AffordableApc";
import ApcCard from "../components/articleProcessingCharges/ApcCard";
import ImportantNote from "../components/articleProcessingCharges/ImportantNote";
import UnderstandingApc from "../components/articleProcessingCharges/UnderstandingApc";
import Content from "../components/global/Content";
import SubHeader from "../components/global/SubHeader";
import NewsLetter from "../components/global/NewsLetter";
import { CONTENT_HEADING, SUB_HEADINGS } from "../constants";
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import { Helmet } from 'react-helmet-async';
function ArticleProcessingCharges() {
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  return (
    <>
      <Helmet>
        <title>Affordable Article Processing Charges at Aakashganga</title>
        <meta
          name="description"
          content="Publish your scientific reports with affordable article processing charges at Aakashganga. Join us in advancing knowledge and global research collaboration"
        />
        <meta property="og:title" content="Affordable Article Processing Charges at Aakashganga" data-react-helmet="true" />
          <meta property="og:description" content="Publish your scientific reports with affordable article processing charges at Aakashganga. Join us in advancing knowledge and global research collaboration" data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/apc" data-react-helmet="true" />
          <link rel="canonical" href="https://www.aakashgangaopen.in/apc" data-react-helmet="true" />
          <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="tagbody">
        <AakashgangaHeader />
        <h1 className="seo-related-tag">
          Article Processing Charges
        </h1>
        <main className="main article-processingg-charges">
          <SubHeader title={SUB_HEADINGS.APC_HEADER} />

          <div className="content-area">
            <div className="container">
              <Content title={CONTENT_HEADING.UNDERSTANDING_APC}> </Content>
              <div className="processingAPCWrap">
                <UnderstandingApc />

                <Content title={CONTENT_HEADING.APC_INDIAN}>
                  <div
                    className="row article-processing pt-0 customarticleprocessing"
                  >
                    <ApcCard
                      heading={"Pre Publishing Double Anonymous Peer Review"}
                      isIndian={true}
                      ApcPrice={"INR 19999"}
                      Discount={"INR 5000"}
                      IntroPrice={"INR 14999"}
                      finalPrice={"INR 14999*"}
                      days={"45"}
                    />
                    <ApcCard
                      heading={"Post Publishing Open Peer Review"}
                      isIndian={true}
                      ApcPrice={"INR 14999"}
                      Discount={"INR 8000"}
                      IntroPrice={"INR 6999"}
                      // IntroGrant={"INR 3000"}
                      finalPrice={"INR 6999*"}
                      days={"30"}
                    />
                  </div>
                </Content>
                <Content title={CONTENT_HEADING.APC_FOREGIN}>
                  <div
                    className="row article-processing pt-0 customarticleprocessing mb-0"
                  >
                    <ApcCard
                      heading={"Pre Publishing Double Anonymous Peer Review"}
                      isIndian={false}
                      ApcPrice={"US$ 699"}
                      Discount={"US$ 300"}
                      finalPrice={"US$ 399"}
                      days={"45"}
                    />
                    <ApcCard
                      heading={"Post Publishing Open Peer Review"}
                      isIndian={false}
                      ApcPrice={"US$ 599"}
                      Discount={"US$ 300"}
                      finalPrice={"US$ 299"}
                      days={"30"}
                    />
                  </div>
                </Content>

                <div className="p-3 text-center text-white blue-border-text customarticleprocessingbackgrd">
                  <p className="m-0 tagp">
                    *If you are located in a South Asian country, please connect
                    to us at <br />{" "}
                    <Link
                      to="mailto:contactus@vikramshilaedu.in"
                      className="underline text-white taga"
                    >
                      contactus@vikramshilaedu.in
                    </Link>{" "}
                    to avail special rates for publishing your article.
                  </p>
                </div>

                <Content
                  title={CONTENT_HEADING.ADD_AMENDMENTS_TO_CURRENT_ARTICLE}
                >
                  <div className="container p-0">
                    <div className="ag_publishjournal_text maxWidthManage m-0 foreign-nation text-center mt-5 mb-5">
                      <h4 className="customarticleprocessingcolor" tagh4>
                        For Indian & Foreign Nationals
                      </h4>
                      <h6>Amendment Charges 0</h6>
                      <p className="m-0 tagp">
                        <span>**</span>We offer two amendments free of cost,
                        after which the article will be <br /> considered a new
                        version or update of the existing article.
                      </p>
                    </div>
                  </div>
                </Content>

                <Content title={CONTENT_HEADING.UPDATE_ARTICLE}>
                  <div className="row m-0 updating-article">
                    <div className="col-lg-6">
                      <h4 className="customarticleprocessingfont">
                        Indian Nationals
                      </h4>
                      <p className="tagp">
                        <strong>
                          We offer a discount of INR 5000 on the final Article
                          Processing Charge.
                        </strong>
                      </p>
                      <p className="tagp indNat">
                        <span>**</span>The update cost is treated as a new
                        version of the article, and this will go through the
                        same process as a new article.
                      </p>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-4">
                      <h4 className="customarticleprocessingfont tagh4">
                        Foreign Nationals
                      </h4>
                      <p className="tagp">
                        <strong>
                          To update an existing article on the platform, please
                          write to us at
                        </strong>
                      </p>
                      <p className="tagp indNat">
                        <Link
                          className="underline"
                          to="mailto:contactus@vilramshilaedu.in"
                        >
                          contactus@vikramshilaedu.in
                        </Link>
                      </p>
                    </div>
                  </div>
                </Content>
                <div className="importantToRead">
                  <ImportantNote />
                </div>

                <div className="container updating-article p-0">
                  <div className="ag_publishjournal_text ag_publishjournal_padding text-center mt-0">
                    <h4
                      className="customarticleprocessingcolor tagh4"
                    >
                      We believe in Open Communication
                    </h4>
                    <p className="m-0 tagp fontSize22">
                      We encourage you to reach out to us with any questions
                      about pricing, <br /> discounts, or payment options. We
                      want you to succeed, and help you <br /> navigate the
                      process effortlessly.
                    </p>
                    <div className="ag_special_offer_btn">
                      <Link
                        to="/foreign-societies-partnerships"
                        target="_blank"
                        className="ag_btn_red taga"
                      >
                        Contact us
                      </Link>
                    </div>
                    <p className="mb-0 tagp fontSize20">
                      <i>
                        <span>**</span>We may run regular offers on our APCs.
                        Please keep visiting our{" "}
                        <Link to="/" target="_blank">
                          <strong className="underline">homepage</strong>{" "}
                        </Link>
                        for details.
                      </i>
                    </p>
                  </div>
                </div>

                <AffordableApc />
              </div>
            </div>
          </div>
        </main>
        <NewsLetter />
        <AakashgangaFooter />
      </div>
    </>
  );
}

export default ArticleProcessingCharges;
