import React from "react";

const ApcCard = ({
  heading,
  isIndian,
  ApcPrice,
  Discount,
  IntroPrice,
  IntroGrant,
  finalPrice,
  days
}) => {
  return (
    <div
      className="col-lg-5 article_col_box px-lg-0 px-3 customapccard"
    >
      <div className="row mx-0">
        <div
          className="col-lg-12 py-3 mb-3 px-5 customHeadapccard backgroudAPCCharge"
          
        >
          <div className="top_title text-center tagh4">
            <h4 className="text-white">
              {" "}
              {heading}{" "}
            </h4>
          </div>
        </div>
        <div className="row m-0 px-4">
          <div className="col-lg-8 col-8 article-table-title article customarticleapccard-processing">
            <p className="tagp"
            >
              Article Processing Charge
            </p>
          </div>
          <div className="col-lg-4 col-4 article-table-data article">
          <p className="tagp">
              <s>{ApcPrice}</s>
            </p>
          </div>
        </div>
        <div className="row m-0 px-4">
          <div className="col-lg-8 col-8 article-table-title article customarticleapccard-introductory">
            <p className="tagp"
            >
              Introductory Discount
            </p>
          </div>
          <div className="col-lg-4 col-4 article-table-data article customarticleapccard-introductory">
            <p className="tagp"
            >
              {Discount}
            </p>
          </div>
        </div>
        <div className="row m-0 px-4">
          <div className="col-lg-6 col-6 article-table-title article mt-4  mb-2">
          <h5 className="tagh5">Final Price</h5>
          </div>
          <div className="col-lg-6 col-6 article-table-data article mt-4">
          <h5 className="price tagh5">{finalPrice}</h5>
          </div>
        </div>
        {isIndian ? (
          <>
            <div className="row m-0 px-4">
              <p
                className="pt-1 customapccardimpinfo tagp"
              >
                <strong>Important Information:</strong>
              </p>
            </div>
            <div className="row m-0 px-4">
            <ul className="tagul underList">
            <li className="tagli">
                  <span className="red-dit">*</span>The article turn-around time
                  is within<br/>{days} days.
                </li>
                <li className="tagli">
                  <span className="red-dit">*</span>The platform exercises the
                  right to withdraw the offer anytime.
                </li>
                <li className="tagli">
                  <span className="red-dit">*</span>All discount applied to the
                  original amount will be applied at the final invoice stage.
                </li>
               
                
                <li className="tagli">
                  <span className="red-dit">*</span>There is a one time
                  registration charge of INR 1000 that will be refunded to your
                  wallet as soon as your article is published. This is to
                  prevent bots from accessing our system.
                </li>
              </ul>
            </div>{" "}
          </>
        ) : (
          <>
         
            <div className="row m-0 px-4 customapccardimpinfo">
            <ul className="tagul underList">
            <li className="tagli">
            <span className="red-dit">*</span>The article turn-around time
                is within<br/>{days} days.
                </li>
                <li className="tagli">
                  <span className="red-dit">*</span>The platform exercises the
                  righ to withdraw the offer anytime.
                </li>
                <li className="tagli">
                  <span className="red-dit">*</span>All discount applied to the
                  original amount will be applied at the final invoice stage.
                </li>
                <li className="tagli">
                  <span className="red-dit">*</span>There is a one time
                  registration charge of US$50 that will be refunded to your
                  wallet as soon as your article is published. This is to
                  prevent bots from accessing our system.
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
      <div className="col-lg-4"></div>
    </div>
  );
};

export default ApcCard;
