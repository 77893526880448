import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Pagination } from "semantic-ui-react";
import AakashgangaHeader from "../../../components/global/HeaderComponent";
import AakashgangaFooter from "../../../components/global/FooterComponent";
import SubmitArticleModal from "../../../components/Modal/SubmitArticleModal";
import MobileModalAlert from "../../../components/Modal/MobileModalAlert";
import Dropdown from "react-bootstrap/Dropdown";
import notyet from "../../../assets/img/icons/notarticle.svg";
import RelatedArticles from "../../../components/viewArticle/relatedArticles";
import RelatedJournals from "../../../components/viewArticle/relatedJournals";
import Nav from "react-bootstrap/Nav";
import backArrow from "../../../assets/img/icons/blueArrow.svg";
import Tab from "react-bootstrap/Tab";
import { isMobile } from 'react-device-detect';
import {
  getJournalDetail,
  getReadingList,
  getJournalBySpecialization,
  searchJournalAndArticle,
} from "../../../store/apiCalls/dashboard";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../components/common/Select";
import { publicationYear } from "../../../constants";
import { useNavigate,Link, useParams } from "react-router-dom";
import SearchDropdown from "../../../components/SearchDropdown/SearchDropdown";

const SearchHeader = () => {
  const masterData = useSelector((state) => state.MasterDataReducer.MasterData);
  const token = localStorage.getItem("usertoken");
  const navigate = useNavigate();
  const params = useParams();
  const { search } = params;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("articles");
  const [searchKey, setSearchKey] = useState("");
  const [uploadArticle, setUploadArticle] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 10;
  const [readingListData, setReadingListData] = useState({
    subject: [],
    type: [],
  });
  const [readingList, setReadingList] = useState([]);
  const [artcileList, setArtcileList] = useState([]);
  const [journalList, setJournalList] = useState([]);
  const [totalReadingList, setTotalReadingList] = useState([]);
  const [oldToNew, setOldToNew] = useState(-1);
  const [filter, setFilter] = useState(false);
  let totalArticlePages = Math.ceil(totalReadingList?.totalCount / limit);
  let totalJournalPages = Math.ceil(
    totalReadingList?.totalJournalCount / limit
  );
  const [journalData, setJournalData] = useState([]);
  const [journalOptions, setJournalOptions] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);

  const fetchReadingList = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = () => {
      const data = {
        userId: id,
        page,
        limit,
        journal:
          readingListData?.journal === "All" ? "" : readingListData?.journal,
        subject: readingListData?.subject
          ?.map((sub) => {
            return specializationOptions?.find(
              (val) => val?.specialization === sub
            )?._id;
          })
          .join(","),
        type: readingListData?.type
          .map((sub) => {
            return masterData?.typeOfArticledata?.find(
              (val) => val?.articleName === sub
            )?._id;
          })
          .join(","),
        publishYear:
          readingListData?.year === "All" ? "" : readingListData?.year,
      };

      if (oldToNew) {
        data.oldToNew = oldToNew;
      }
      return data;
    };
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const response = await getReadingList(headers, params());
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setReadingList(response?.data?.data?.data);
      setTotalReadingList(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };
  const fetchSearchResult = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    
    const params = () => {
      const data = {
        page,
        limit,
        search: search,
      };
      if(token){
        const { id } = jwt_decode(localStorage.usertoken)
        data.userId = id;
      }
      if (oldToNew) {
        data.sorting = oldToNew;
      }
      return data;
    };
    const headers = { Authorization: `Bearer ${""}` };
    const response = await searchJournalAndArticle(headers, params());
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setReadingList(response?.data?.data);
      setArtcileList(response?.data?.data);
      setJournalList(response?.data?.journal);
      setTotalReadingList(response?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };
  const fetchFilterResult = async () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = () => {
      const data = {
        page,
        limit,
        journal:
          readingListData?.journal === "All" ? "" : readingListData?.journal,
        specialization: readingListData?.subject
          ?.map((sub) => {
            return specializationOptions?.find(
              (val) => val?.specialization === sub
            )?._id;
          })
          .join(","),
        type: readingListData?.type
          .map((sub) => {
            return masterData?.typeOfArticledata?.find(
              (val) => val?.articleName === sub
            )?._id;
          })
          .join(","),
        publishYear:
          readingListData?.year === "All" ? "" : readingListData?.year,
      };
      if(token){
        const { id } = jwt_decode(localStorage.usertoken)
        data.userId = id;
      }

      if (oldToNew) {
        data.sorting = oldToNew;
      }
      return data;
    };
    const headers = { Authorization: `Bearer ${""}` };
    const response = await searchJournalAndArticle(headers, params());
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setReadingList(response?.data?.data);
      setArtcileList(response?.data?.data);
      setJournalList(response?.data?.journal);
      setTotalReadingList(response?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };

  const handleSelectChange = (value, type) => {
    let newReadingListData = { ...readingListData };
    if (type === "journal" && value !=='All') {
      newReadingListData = { ...readingListData, subject: [] };
      setSpecializationOptions(specializationOptions[value]);
    }
    return setReadingListData({ ...newReadingListData, [type]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const cloneSubject = { ...readingListData };
    if (name === "subject") {
      if (cloneSubject.subject.includes(value)) {
        cloneSubject.subject.splice(cloneSubject.subject.indexOf(value), 1);
      } else {
        if (cloneSubject.subject.length < 3) {
          cloneSubject.subject.push(value);
        }
      }
    } else if (name === "type") {
      if (cloneSubject?.type.includes(value)) {
        cloneSubject?.type?.splice(cloneSubject?.type?.indexOf(value), 1);
      } else {
        if (cloneSubject?.type.length < 3) {
          cloneSubject.type.push(value);
        }
      }
    }
    setReadingListData(cloneSubject);
  };

  const clearFilter = () => {
    setFilter(true);
    fetchReadingList();
    fetchFilterResult();
    fetchSearchResult();
    setSpecializationOptions([]);
    setReadingListData({
      journal: "",
      subject: [],
      type: [],
      year: "",
    });
  };

  const getResponseOptions = () => {
    let journal = [];
    journalData &&
      journalData.forEach((element) => {
        journal.push(element);
      });
    journal.unshift({ journalName: "All" });
    return setJournalOptions(journal);
  };

  const getJournalData = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journalType: masterData?.jouranlData?.[0]?._id,
    };
    const response = await getJournalDetail(headers, params);
    if (response?.status === 200) {
      setJournalData(response?.data?.data);
    } else return;
  };

  const getSpecializationData = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      journalName: readingListData?.journal ? readingListData?.journal : "",
    };
    const response = await getJournalBySpecialization(headers, params);
    let journalName = response?.data?.data?.data?.[0]?.journalName;
    let result = journalName.substring(0, 8);
    if (response?.status === 200) {
      if (result === "AyurYoga") {
        let firstArray = response?.data?.data?.specilisation?.[0];
        let anotherArray = response?.data?.data?.specilisation?.[1];
        let finalArray = [...firstArray, ...anotherArray];
        setSpecializationOptions(finalArray);
      } else {
        setSpecializationOptions(response?.data?.data?.specilisation);
      }
    } else return;
  };

  if(searchKey !==search){
    setSearchKey(search);
    fetchSearchResult();
  }

  useEffect(() => {
    getJournalData();
  }, [masterData]);

  useEffect(() => {
    getResponseOptions();
  }, [journalData]);

  useEffect(() => {
    if (readingListData?.journal) {
      getSpecializationData();
    }
  }, [readingListData]);

  useEffect(() => {
    fetchSearchResult();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
  }, [page, filter, oldToNew]);

  return (
    <>
    <AakashgangaHeader />
    <div className="serachPageDiv">
      {/* <DashboardLayout> */}
      
        <div className="articleWrap">
          <div className="articleLeft">
            <h2>Showing Result for “{search}”</h2>
          </div>
        </div>
        <h3 className="commanTitle">Search In</h3>
        <div className="customTabs">
          <Tab.Container defaultActiveKey="newrequests">
            <Nav>
              <Nav.Item>
                <Nav.Link
                  eventKey="newrequests"
                  onClick={() => [
                    setActiveTab("articles"),
                    setPage(1),
                  ]}
                >
                  Articles
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="accepted"
                  onClick={() => [
                    setActiveTab("journals"),
                    setPage(1),
                  ]}
                >
                  Journals
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
        {activeTab === "articles" && (
          <>
            <div className="redingFilterWrap">
              <div className="commanBox">
                <div className="redingFilterArea">
                  <div className="redingFilterList">
                    <div className="fieldWrap selectBox">
                      <Select
                        handleChange={(value) =>
                          handleSelectChange(value, "journal")
                        }
                        selectedValue={readingListData?.journal}
                        options={journalOptions?.map((val) => val?.journalName)}
                        optionValues={journalOptions?.map(
                          (val) => val?.journalName
                        )}
                        labelClass={readingListData?.journal ? "" : "hidden"}
                        label="Journal"
                        placeholder="Journal"
                        name="journal"
                      />
                    </div>
                  </div>
                  <div className="redingFilterList">
                    <div className="fieldWrap selectBox">
                      <SearchDropdown
                        options={specializationOptions
                          ?.filter(
                            (spec) =>
                              spec?.specialization !==
                              "Other Subject (choose this option to register your subject)"
                          )
                          .map((val) => val?.specialization)}
                        onChange={handleChange}
                        selectedValue={readingListData?.subject}
                        name="subject"
                        placeholder={
                          readingListData?.subject?.length === 0 ||
                          !readingListData?.subject
                            ? "Subject"
                            : readingListData?.subject?.length > 1
                            ? readingListData?.subject[0] +
                              " +" +
                              String(readingListData?.subject.length - 1)
                            : readingListData?.subject[0]
                        }
                        label="Subject"
                        labelClass={
                          readingListData?.subject?.length !== 0 ? "" : "hidden"
                        }
                        removeAsterisk={true}
                      />
                    </div>
                  </div>
                  <div className="redingFilterList">
                    <div className="fieldWrap selectBox">
                      <SearchDropdown
                        options={masterData?.typeOfArticledata?.map(
                          (article) => article?.articleName
                        )}
                        onChange={handleChange}
                        selectedValue={readingListData?.type}
                        name="type"
                        placeholder={
                          readingListData?.type?.length === 0 ||
                          !readingListData?.type
                            ? "Type"
                            : readingListData?.type?.length > 1
                            ? readingListData?.type[0] +
                              " +" +
                              String(readingListData?.type.length - 1)
                            : readingListData?.type[0]
                        }
                        label="Type"
                        labelClass={
                          readingListData?.type?.length !== 0 ? "" : "hidden"
                        }
                        removeAsterisk={true}
                      />
                    </div>
                  </div>
                  <div className="redingFilterList ">
                    <div className="fieldWrap selectBox">
                      <Select
                        handleChange={(value) =>
                          handleSelectChange(value, "year")
                        }
                        selectedValue={readingListData?.year}
                        options={publicationYear?.map((item) => item?.key)}
                        optionValues={publicationYear?.map(
                          (item) => item?.value
                        )}
                        labelClass={readingListData?.year ? "" : "hidden"}
                        label="Publication Year"
                        placeholder="Publication Year"
                        name="year"
                      />
                    </div>
                  </div>
                  <div className="redingFilterList actionWrap">
                    <span className="filterText">Filter</span>
                    <b onClick={clearFilter}>Clear All</b>
                    <Link
                      class="button button-primary"
                      to=""
                      onClick={fetchFilterResult}
                    >
                      Apply
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 sortWrap">
                <div className="customDrop">
                  <Dropdown className="removeArrow">
                    <Dropdown.Toggle variant="success">Sort By</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className={oldToNew === -1 ? "active" : ""}
                        onClick={() => {
                          setOldToNew(-1);
                        }}
                      >
                        New to Old
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={oldToNew === 1 ? "active" : ""}
                        onClick={() => {
                          setOldToNew(1);
                        }}
                      >
                        Old to New
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="row">
              {artcileList && artcileList.length > 0 ? (
                artcileList?.map((article, index) => {
                  const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                  const contentWithoutSpecialChars = () => {
                    return article?.abstract?.replace(specialCharPattern, "");
                  };
                  return (
                    <div className="col-md-6">
                      <RelatedArticles
                        index={index}
                        published={article?.updatedAt}
                        id={article?.readinglistId}
                        title={article?.title}
                        userTitle={article?.authorDetails?.author?.[0]?.title}
                        userName={article?.authorDetails?.author?.[0]?.fullName}
                        contentWithoutSpecialChars={contentWithoutSpecialChars}
                        typeOfArticle={
                          article?.typeofarticles?.[0]?.articleName
                        }
                        journalName={article?.submissionDetail?.journalName}
                        subjectName={article?.subjectDetail?.[0]?.subjectName}
                        specialization={
                          article?.specializations?.[0]?.specialization
                        }
                        fetchReadingList={fetchSearchResult}
                        readingList={artcileList}
                        articleId={article?._id}
                        readingflag={article?.readingflag}
                        pageName="search"
                      />
                    </div>
                  );
                })
              ) : (
              
                <div className="tabContentWrap">
                  <div className="notArticle notArticleRedingList notArticleNoFix">
                    <figure>
                      <img src={notyet} alt="No Article" />
                    </figure>
                    <h2>We are currently populating our website</h2>
                    <p className="d-inline-block w-100">
                    In the meantime you maybe interested in:
                      <span></span>
                    </p>
                  </div>
                  <div className="searchTag">
                  {!token && (
                    <span onClick={()=>isMobile?setMobileAlert(true):window.open("/login")}>
                    <i/>Contribute an Article
                  </span>
                  )}
                   {token && (
                    <span onClick={()=>isMobile?setMobileAlert(true):setUploadArticle(true)}>
                    <i/>Contribute an Article
                  </span>
                  )}
                    <span onClick={()=> window.open('https://vikramshilaedu.in/resourcecentre')}>
                      <i/>Resource Centre
                    </span>
                    <span onClick={()=>window.open('/faq')}>
                      <i/>Frequently Asked Questions
                    </span>
                    <span onClick={()=>window.open('/registration')}>
                      <i/>How to Register
                    </span>
                    <span onClick={()=>window.open('/peer-review-models')}>
                      <i/>Peer Review Models 
                    </span>
                    <span onClick={()=>window.open('/apc')}>
                      <i/>Article Publishing Charge
                    </span>
                    <span onClick={()=>window.open('/open-access')}>
                      <i/>Open Access 
                    </span>
                    <span onClick={()=>window.open('/about-megajournals')}>
                      <i/>Mega Journals 
                    </span>
                  </div>
                </div>
              )}
            </div>
            {totalArticlePages > 1 && (
              <div className="paginationWrap mt-4">
                <Pagination
                  boundaryRange={1}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  activePage={page}
                  prevItem={{ content: <b class="icon-arrow-left" /> }}
                  nextItem={{ content: <b class="icon-arrow-right" /> }}
                  onPageChange={(event, data) => setPage(data.activePage)}
                  totalPages={totalArticlePages}
                />
              </div>
            )}
          </>
        )}
        {activeTab === "journals" && (
          <>
            <div className="row">
              {journalList && journalList.length > 0 ? (
                journalList?.map((article, index) => {
                  const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
                  const contentWithoutSpecialChars = () => {
                    return article?.journalDescription?.replace(
                      specialCharPattern,
                      ""
                    );
                  };
                  return (
                    <div className="col-md-6">
                      <RelatedJournals
                        index={index}
                        published={article?.publishArticle}
                        title={article?.journalName}
                        userTitle={article?.abbreviation}
                        contentWithoutSpecialChars={contentWithoutSpecialChars}
                        readingList={readingList}
                        journalId={article?._id}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="tabContentWrap">
                  <div className="notArticle notArticleRedingList">
                    <figure>
                      <img src={notyet} alt="No Article" />
                    </figure>
                    <h2>No results Found!</h2>
                    <p className="d-inline-block w-100">
                      Here are some suggestions to help with your search:
                      <span className="pl-5">
                        1. Use relevant keywords and phrases related to your
                        topic <br />
                        2. Be specific about your request to find relevant
                        information
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>

            {totalJournalPages > 1 && (
              <div className="paginationWrap mt-4">
                <Pagination
                  boundaryRange={1}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  activePage={page}
                  prevItem={{ content: <b class="icon-arrow-left" /> }}
                  nextItem={{ content: <b class="icon-arrow-right" /> }}
                  onPageChange={(event, data) => setPage(data.activePage)}
                  totalPages={totalJournalPages}
                />
              </div>
            )}
          </>
        )}
        <div className="backBtnWrap mt-5">
          <a onClick={() => window.history.back()}>
            <span>Back</span>
            <img src={backArrow} alt="#" />
          </a>
        </div>
      {/* </DashboardLayout> */}
    </div>
    <AakashgangaFooter />
    <SubmitArticleModal
        uploadArticle={uploadArticle}
        setUploadArticle={setUploadArticle}
      />
      <MobileModalAlert mobileAlert={mobileAlert}
        setMobileAlert={setMobileAlert} />
    </>
  );
};
export default SearchHeader;
