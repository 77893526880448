import React, { useRef,useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch,useSelector } from "react-redux";
import DashboardLayout from "../../../layout/dashboard";
import { getDateFormat } from "../../../utils";
import mudraIcon from "../../../assets/img/icons/mudrakosh.png";
import copytext from "../../../assets/img/icons/copy.svg";
import closeIcon from "../../../svg/modal-close.svg";
import matka from "../../../assets/img/icons/matka.svg";
import rupee from "../../../assets/img/icons/rupee.svg";
import coupanIcon from "../../../assets/img/icons/save.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import active_info from "../../../svg/active-info.svg";
import notyet from "../../../assets/img/icons/notarticle.svg";
import {downloadOrsendReceipt} from "../submitArticle/queries";
import { toast } from "react-toastify";
import { downLoadFile } from "../../../utils";
import { getProfileDetails } from "../../../store/apiCalls/profileDetails";
import { getMudras, getOffer,getTransactionHistory,mudraKoshDetails } from "../../../store/apiCalls/dashboard";
import { Helmet } from 'react-helmet-async';

function mudraKosh() {
  const searchRef = useRef("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.LoginReducer);
  const [page, setPage] = useState(1);
  const limit = 10;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { id } = jwt_decode(localStorage.usertoken);
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const handleShow = () => setShow(true);
  const [collapseActive, setIsActive] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [crossButton, setCrossButton] = useState(false);
  const [mudrasData, setMudrasData] = useState();
  const [copyreferalCode, setCopyreferalCode] = useState("");
  const [historyResult, setHistoryResult] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const [transactionHistory, settransactionHistory] = useState([]);
  const [transactionSorting, settransactionSorting] = useState({'title':'',"typeSorting":"","amount":'',"date":''});
  const [downloadInvoice, setdownloadInvoice] = useState({txnId:'',typeOfPayment:'',articleId:''});
  const [otherMudraDetail, setotherMudraDetail] = useState({mudraCreditMessageKey:true,articleId:'',title:'',amnt:0});

  const expandClick = (event) => {
    setIsActive((current) => !current);
  };

  function openHistory() {
    setHistoryResult(true);
  }

  const handleCopyText = (e) => {
    setCopyreferalCode(e.target.value);
  };

  const copyToClipboard = (value) => {
    copy(value);
   return toast.success("Copied");
  };
  const fetchUserData = () => {
    const fetchdata = async () => {
      const params = { id: id };
      return getProfileDetails(headers, params);
    };
    fetchdata()
      .then((userData) => {
        if (userData.status === 200 && userData?.data?.data) {
          setUserDetails(userData?.data?.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const fetchMudrasData = () => {
    const fetchdata = async () => {
      const params = {"userId":id};
      return getMudras(headers, params);
    };
    fetchdata()
      .then((mudrasData) => {
        setMudrasData(mudrasData.data);
      })
      .catch((err) => console.log(err));
  };
  const fetchOfferData = () => {
    const fetchdata = async () => {
      const params = { page, limit, status: "live" };
      return getOffer(headers, params);
    };
    fetchdata()
      .then((offerData) => {
        
        setOfferData(offerData?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchUserTransactionHistory = (filter) => {
    if(filter?.search === ''){
      setCrossButton(false);
    }
    const fetchdata = async () => {
      const params = {"userId":id,"page":page, "limit":200};
      if(filter?.search)
      params.search=filter?.search
      if(filter?.sortTitle)
      {
        if(filter?.sortTitle==='title')
        params.title=filter?.sortType
        else if(filter?.sortTitle==='typeSorting')
        params.typeSorting=filter?.sortType
        else if(filter?.sortTitle==='amount')
        params.amount=filter?.sortType
        else if(filter?.sortTitle==='date')
        params.date=filter?.sortType
      }
      
      return getTransactionHistory(headers, params);
    };
    fetchdata()
      .then((history) => {
        if(history.status===200)
        {
          settransactionHistory(history?.data?.data);
        }
      })
      .catch((err) => console.log(err));
  };

  function sortingTrans(sortingType)
  {
    const existSort=transactionSorting
    let sortOrder='-1';
    const sort={sortType:'',sortTitle:sortingType}
    if(sortingType==='title')
    {
      if(existSort.title==='-1')
      sortOrder='1';
      existSort.title=sortOrder;
      sort.sortType=sortOrder;
    }
    else if(sortingType==='typeSorting')
    {
      if(existSort.typeSorting==='-1')
      sortOrder='1';
      existSort.typeSorting=sortOrder;
    }
    else if(sortingType==='amount')
    {
      if(existSort.amount==='-1')
      sortOrder='1';
      existSort.amount=sortOrder;
    }
    else if(sortingType==='date')
    {
      if(existSort.date==='-1')
      sortOrder='1';
      existSort.date=sortOrder;
    }
    sort.sortType=sortOrder;
    settransactionSorting({...existSort});
    fetchUserTransactionHistory(sort)
  }

  const getOtheMudraDetailsAndUpdate = async (type) => {
    dispatch({ type: "SET_LOADER", payload: true });
    let params = {userId: id,typeOfApc:user.nationality };
    if(type==='post')
    {
      params.mudraCreditMessage=true;
      params.update =true;
    }
    
    const response = await mudraKoshDetails(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      const data=response.data.data;
      const mudraDetl=otherMudraDetail;
      mudraDetl.mudraCreditMessageKey=data.mudraCreditMessageKey;
      if(data?.data.length>0 && type==='get')
      {
        let payemnt=0;
        if(data?.data[0].reviewType.typeOfReview==='pre-publication peer review only')
        payemnt=data.finalPayment[0].introductoryPrice
        else
        payemnt=data.finalPayment[1].introductoryPrice
        mudraDetl.articleId=data?.data[0]._id;
        mudraDetl.title=data?.data[0].title;
        mudraDetl.amnt=payemnt;
      }
      else if(type==='get'){
        mudraDetl.articleId='';  mudraDetl.title='';mudraDetl.amnt='';
      }
      setotherMudraDetail({...mudraDetl})
      
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };
   function hideEarnedMsg()
   {
      getOtheMudraDetailsAndUpdate('post')
   }
  
  useEffect(() => {
    fetchOfferData();
    fetchUserData();
    fetchMudrasData();
    fetchUserTransactionHistory();
    getOtheMudraDetailsAndUpdate('get');
  }, []);

  function convertDateFormate(date)
  {
    let newDate = new Date(date);
    let getDate = newDate.getDate();
    let getYear = newDate.getFullYear();
    let getMonth = newDate.toLocaleString('en-US', { month: 'short' });
    return getDate + " " + getMonth + " " + getYear;
  };
  const sucessHandlerDownLoadReceipt = (details) => {
    if (details.status === 200) {
        const url = details.data.data
        let filename='initialPaymentReceipt';
         if(downloadInvoice.typeOfPayment==="APC")
        filename='finalPaymentReceipt';
        downLoadFile(url, `${filename}${downloadInvoice.txnId}.pdf`);
    }
  }

  function downLoadReciepts(details) {
    setdownloadInvoice({ txnId: details.txnid, typeOfPayment: details.typeOfPayment, articleId: details.articleId })
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const payloads = { "articleId": details.articleId }
    if (details.typeOfPayment === "APC") {
      payloads.finalPayment = true
      payloads.download = true
    }
    else {
      payloads.initialPayment = true
      payloads.download = true
    }
    const queryParams = {
      payload: payloads,
      headers,
    };
    downloadSendReceipt(queryParams);

  }

  const { mutate: downloadSendReceipt } = downloadOrsendReceipt(sucessHandlerDownLoadReceipt);

  const handleRedirect = (e, path) => {
    if (e !== "") e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const handleClick = (articleId) => {
    handleRedirect("", `/viewPublishedArticle/${articleId}`);
  };

  return (
    <>
     <Helmet>
     <meta name="robots" content="noindex, nofollow" />
    </Helmet>
      <DashboardLayout>
        {otherMudraDetail.articleId!=='' &&
        <div className="profileSetupWrap">
          <p>
            <b>ATTENTION: Your payment is due</b>
            <span>{otherMudraDetail.title}</span>
          </p>
          <div className="profileSetupBtn">
          <Link to={`/dashboard/edit-article/${otherMudraDetail.articleId}`}> <button className="button button-primary">Pay {user.nationality==='outSideIndia'?'$':'INR'} {otherMudraDetail.amnt}</button></Link>
          </div>
        </div>}
        {otherMudraDetail.mudraCreditMessageKey===false &&<div className="mudraAlert">
          <div className="mudraLeft">
            <figure>
              <img src={mudraIcon} alt="#" />
            </figure>
            <p>
              <span>Congratulations!</span>
              <small>You have earned 500 Mudras</small>
            </p>
          </div>
          <b onClick={()=>hideEarnedMsg()}>
            <img src={closeIcon} alt="#" />
          </b>
        </div>}

        <div className={user?.nationality === "outSideIndia" ? "midraMainBox midraOutSideIndia" : "midraMainBox"}>
          <div className="midraTop">
            <div className="midraLeft">
              <div className="commanBox balanceWrap">
                <p>
                  <img src={rupee} alt="#" />
                  Balance
                </p>
                <p>
                  <small>{user.nationality==='outSideIndia'?'USD':'INR'}</small> <big>{mudrasData?.mudras}</big>
                </p>
              </div>
            </div>
            {
              user?.nationality === "outSideIndia" ? null : (
                <div className="midraRight">
              <div className="commanBox mudrasWrap">
                <div className="mudrasHead">
                  <h3>Total Mudras</h3>
                  <div className="customTool">
                    {["bottom"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Popover>
                            <Popover.Body>
                              <div className="popoverToolWrap">
                                <p>
                                  Mudras is Vikramshila currency you earn on the
                                  website. Use these Mudras to pay for services
                                  or towards Article Processing Charge to help
                                  you publish your article. 100 Mudras = INR
                                  100.
                                </p>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <img
                          className="pe-cursor ps-1"
                          src={active_info}
                          alt="no img"
                        />
                      </OverlayTrigger>
                    ))}
                  </div>
                </div>
                <p className="mudTitle">
                  <big>{mudrasData?.mudras}</big>
                  
                </p>
                <div className="mudrasFoot">
                  <p>Earn points by reviewing articles!</p>
                  <Link to="/history">History</Link>
                </div>
              </div>
            </div>
              )
            }
            
          </div>
          <div className="midraLeft">
            <div className="mudraSearch">
              <h3>Transaction History </h3>
              {(transactionHistory.length>0 || searchRef.current?.value!=='') &&<span className="searchHistory" >
                <input type="search" placeholder="Search by title" ref={searchRef} onKeyUp={(e)=>{setCrossButton(true),fetchUserTransactionHistory({'search':e.target.value})}} />
                {crossButton && 
                <b onClick={()=>{setCrossButton(false),searchRef.current.value ='';fetchUserTransactionHistory()}}>X</b>
                }
              </span>}
            </div>
            <div className="historyTable">
              
            {transactionHistory.length===0 && <div className="tabContentWrap">
                <div className="notArticle">
                  <figure>
                    <img src={notyet} alt="No Article" />
                  </figure>
                  <h2>No Transaction yet!</h2>
                    <p className="d-inline-block w-100">
                       {searchRef.current?.value===''?'You have not pay any Transaction.':'No result found'} 
                    </p>
                </div>
                </div>         
            
            }
            
            {transactionHistory.length>0 &&   <table>
                <thead>
                  <tr>
                    <th className="sortingWrap">
                      <span>
                        Title
                        <b onClick={()=>sortingTrans('title')} className="sort" />
                      </span>
                    </th>
                    <th className="sortingWrap">
                      <span>
                        Type
                        <b onClick={()=>sortingTrans('typeSorting')} className="sort" />
                      </span>
                    </th>
                    <th className="sortingWrap"> 
                      <span>
                        Date
                        <b onClick={()=>sortingTrans('date')} className="sort" />
                      </span>
                    </th>
                    <th className="sortingWrap text-right">
                      <span className="justify-content-end">
                        Amount
                        <b onClick={()=>sortingTrans('amount')} className="sort" />
                      </span>
                    </th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>
               
                {transactionHistory.map((trans) => (
                  <>
                 <tr className="empty">
                 <td colspan="5">&nbsp;</td>
               </tr>
                   
                  <tr>
                    <td>
                      <div className="hisTableBox">
                        <a className="transText showLimitedChar" onClick={(e) => handleClick(trans?.articleId)}> {trans?.article?.[0]?.title}</a>
                        <div className="txnWrap">
                          <b>TXN ID:</b> <i className="transNid">{trans?.txnid}</i>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="hisTableBox">{trans?.typeOfPayment}</div>
                    </td>
                    <td>
                      <div className="hisTableBox">
                        <span>{convertDateFormate(trans?.createdAt)}</span>
                      </div>
                    </td>
                    <td>
                      <div className="hisTableBox text-right">
                        <div className="mudraContentBox">
                        <big className="inrText">-{trans.amount}</big>
                        <span className="debitColor">Debit</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="hisTableBox">
                        <div className="downFileWrap">
                          <a href="javascript:void(0)" onClick={()=>downLoadReciepts(trans)}className="downloadFile">
                            <img src="" />
                          </a>
                          <span>Download</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </>
                  ))}
                 
                   
                  
                </tbody>
              </table>}
             
            </div>
          </div>
          <div className="midraRight">
            {
              user?.nationality === "outSideIndia" ? null : (
                 <div className="referralWrap commanBox">
              <h3>My Referral Code</h3>
              <p>
                <b>{userDetails?.promoCode}</b>
                <span>
                  <img
                    src={copytext}
                    alt="copy"
                    onClick={() => copyToClipboard(userDetails?.promoCode)}
                  />
                </span>
              </p>
              <i>
                <img src={matka} alt="#" />
              </i>
            </div>
              )
            }
           
            <div className="couponsWrap">
              <h3><img src={coupanIcon} alt="#" /> Available Coupons</h3>
              <div className="couponsBox">
                {offerData &&
                  offerData?.length > 0 &&
                  offerData?.map((data) => {
                    return (
                      <>
                        <div className="couponsList">
                          <div className="couponsOff">
                            <div className="">
                              Coupon Code
                              <span className="coopanCode">
                                {data?.offerCode}{" "}
                                <i>
                                  <img src={copytext} alt="copy" onClick={() => copyToClipboard(data?.offerCode)}/>
                                </i>
                              </span>
                            </div>
                            <b>
                              {data?.currency} {data?.value} Off
                            </b>
                          </div>
                          <div className="couponsTmc">
                            <span>
                              <b>Expiration:</b> {getDateFormat(data?.endDate)}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Reject Review</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="rejectModal">
            <div className="row">
              <div className="col-md-12">
                <p>Please specify your reason:</p>
              </div>
              <div className="col-md-12">
                <label className="customRadio">
                  <input type="radio" name="reviewRadio" />
                  <b></b>
                  <span>It is my subject but it is not my specialization</span>
                </label>
              </div>
              <div className="col-md-12">
                <label className="customRadio">
                  <input type="radio" name="reviewRadio" />
                  <b></b>
                  <span>Sorry, I cannot meet the deadline </span>
                </label>
              </div>
              <div className="col-md-12">
                <label className="customRadio">
                  <input type="radio" name="reviewRadio" />
                  <b></b>
                  <span>Not my subject area </span>
                </label>
              </div>

              <div className="col-md-12">
                <label className="customRadio">
                  <input type="radio" name="reviewRadio" />
                  <b></b>
                  <span>It is not my subject </span>
                </label>
              </div>

              <div className="col-md-12">
                <textarea
                  placeholder="Other reason..."
                  className="inputField"
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4">
          <button
            class="button button-primary"
            type="submit"
            onClick={handleClose}
          >
            SUBMIT
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={historyResult}
        size="md"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal md-x"
      >
        <Modal.Header>
          <h5 className="modal-title pr-color">History</h5>
          <button
            type="button"
            className="btn-close"
            onClick={(e) => setHistoryResult(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body className="pt-0 pb-2">
          <div className="historyModalWrap">
            <div className="historyList">
              <p>
                <b>Referral Code</b>
                <i>12 Oct 2022</i>
              </p>
              <span>250 Mudras</span>
            </div>
            <div className="historyList">
              <p>
                <b>Referral Code</b>
                <i>12 Oct 2022</i>
              </p>
              <span>250 Mudras</span>
            </div>
          </div>
          <div className="modalFoot">
            <button className="button button-primary">CLOSE</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default mudraKosh;
