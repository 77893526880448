import { useNavigate,Link } from "react-router-dom";
import {useRef } from "react";
import NewsLetter from "../components/global/NewsLetter";
import {
  aboutAkashGangaData,
  automateSubmissionSystemData,
  automatePublishingEditorData,
  automatedProfanitySimilarityData,
  openPeerReviewData,
} from "../components/sellPageForeign/data";
import PeraTextData from "../components/global/PeraTextData";
import JoinPublishingCommunityForm from "../components/sellPageForeign/JoinPublishingCommunityForm";
/*eslint-disable*/
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import { Helmet } from 'react-helmet-async';

function SellPageForeign() {
  const ref = useRef(null);
  
  const handleContent = (type) => {
    const element = document.getElementById(`viewScroll${type}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClick = () => {
    window.scrollTo({
      top: 5500,
      behavior: 'smooth',
  });
}
  
  const navigate = useNavigate();
  return (
    <>
     <Helmet>
          <title>Global Scholarly Research: Aakashganga Open Access Journal Platform</title>
          <meta name="description" content="Launch your own open access journal with Aakashganga, a digital platform dedicated to supporting universities, institutes, and societies globally. Discover our automated submission system, transparent peer review process, and global visibility initiatives." />

          <meta property="og:title" content="Global Scholarly Research: Aakashganga Open Access Journal Platform" data-react-helmet="true" />
          <meta property="og:description" content="Launch your own open access journal with Aakashganga, a digital platform dedicated to supporting universities, institutes, and societies globally. Discover our automated submission system, transparent peer review process, and global visibility initiatives." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/foreign-societies-partnerships" data-react-helmet="true" />

    <link rel="canonical" href="https://www.aakashgangaopen.in/foreign-societies-partnerships" data-react-helmet="true" />
    <meta name="robots" content="index, follow" />
    </Helmet>
    <div className="tagbody">
    <AakashgangaHeader />
      <h1 className="seo-related-tag">
      Empowering Universities, Institutes, and Societies Globally
      </h1>
      <main className="main  ">
        <div className="">
          <div className="container">
            <div className="sell-banner-wrp">
              <div className="sell-banner">
                <img
                  src="assets/images/foreign-university.gif"
                  alt="Universities, Societies" className="tagimg"
                />
              </div>
              <div className="sell-banner-text">
                <h3 className="title tagh3">
                  Publ<span>i</span>sh Your Open <br></br> Access Journal With
                  Us
                </h3>
                <p className="tagp">
                  Empowering Universities,<br></br> Institutes, and Societies
                  Globally
                </p>
                <div
                  className="ag_special_offer_btn customSellpageflexdirection"
                >
                  <button  className="ag_btn_red taga" onClick={() => window.scrollTo(0, 4000)} >
                    Connect Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container container-full Transforming-btm-col">
            <div className="ag_heading_center_wrap mt-5">
              <h2 className="tagh2">
                Transform<span>i</span>ng Global Scholarly Research
              </h2>
            </div>
            <div className="Transforming-btm-text text-center mb-5">
              <h4 className="tagh4">
                Are you looking for an Open Access Publisher to launch your own
                journal?<br/>
                  Are you tired of having your Open Access Journal Proposals{" "}
                  <br/>
                 rejected by traditional publishers?
              </h4>
              <p className="pera-text foraing spacing-more tagp">
                Well, look no further. Partner with Aakashganga to achieve your
                Journal Publishing Objectives.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row about-sec align-items-center mt-4">
            <div className="col-lg-6">
              <h4 className="tagh4 mb-0">About Aakashganga</h4>
              <PeraTextData data={aboutAkashGangaData} />
              <div
                className="ag_special_offer_btn mt-3 customSellpageflexdirection"
              >
                <a href="javascript:;" className="ag_btn_red taga" onClick={() => window.scrollTo(0, 4700)}  >
                  Connect Now
                </a>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <img
                src="assets/images/sell-logo.png"
                className="sell_logo tagimg"
                alt="Aakashganga Logo"
              />
              <span>Bringing Your Research Ideas To Life</span>
            </div>
          </div>
        </div>

        <div className="global-publish-challenges">
          <div className="ag_heading_center_wrap my-5">
            <h2 className="tagh2">Global Publishing Challenges</h2>
          </div>

          <div className="container">
            <div className="row main-global-publish-challenge challenge-1">
              <div
                className="col-lg-6 global-publisher-col p-0"
              >
                <div className="foreignBox">
                <h4 className="tagh4">
                  Automated submission<br/>system for articles
                </h4>
                <PeraTextData  data={automateSubmissionSystemData} />
                </div>
              </div>
              <div className="col-lg-6 global-publisher-col publisher-2 p-0"
              >
                <div className="foreignBox">
                  <h4 className="tagh4">
                    Automated Publishing Editor Review and Peer Review Selection
                  </h4>
                  <PeraTextData data={automatePublishingEditorData} />
                </div>
              </div>
            </div>
            <div className="row main-global-publish-challenge challenge-2">
              <div
                className="col-lg-6 global-publisher-col publisher-3 p-0"
              >
                <div className="foreignBox">
                <h4 className="tagh4">
                  Fully automated Profanity and Similarity Index checker
                </h4>
                <PeraTextData data={automatedProfanitySimilarityData} />
                </div>
              </div>
              <div
                className="col-lg-6 global-publisher-col publisher-4 p-0"
              >
                 <div className="foreignBox">
                <h4 className="tagh4">
                  Post Publishing<br/>Open Peer Review
                </h4>
                <PeraTextData data={openPeerReviewData} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container container-full ">
          <div className="ag_heading_center_wrap mt-3 mb-5">
            <h2 className="tagh2">Still not convinced? Read on</h2>
          </div>

          <div className="row foreign">
            <div className="col-lg-12 convinced-img-wrp convinced-col-1">
              <div className="convinced-img-col">
                <img src="assets/images/convinced-1.png" className="tagimg" alt="Convinced_Home" />
              </div>
              <div className="convinced-right-text convinced-text">
                <div className="convinced-text-col">
                  <div className="leftSpace leftSpace01">
                  <h4 className="tagh4">A Future Ready Home For Your Journals</h4>
                  <p className="journals tagp">
                    We leverage technology to bring you a smooth, effortless and
                    transparent publishing experience. Our automated workflow
                    simplifies complex manual tasks and will meet all your
                    current and future journal publishing needs.
                  </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 convinced-img-wrp convinced-col-2">
              <div className="convinced-left-text convinced-text">
                <div className="convinced-text-col">
                <div className="leftSpace">
                  <h4 className="tagh4">Global Visibility</h4>
                  <p className="globalvisibility tagp">
                    Each journal within our network is meticulously curated and
                    aligned with quality standards that contribute to a
                    favourable outlook for indexing. This is done through a
                    dedicated focus on content quality, rigorous peer-review
                    processes, and compliance with industry best practices,
                    reaching researchers worldwide.
                  </p>
                  </div>
                </div>
              </div>
              <div className="convinced-img-col">
                <img src="assets/images/convinced-2.png" className="tagimg" alt="Global Visiblity " />
              </div>
            </div>

            <div className="col-lg-12 convinced-img-wrp convinced-col-3">
              <div className="convinced-img-col">
                <img src="assets/images/convinced-3.png" className="tagimg" alt="Peer Reviewers" />
              </div>
              <div className="convinced-right-text convinced-text">
                <div className="convinced-text-col">
                <div className="leftSpace03">
                  <h4 className="tagh4">Peer Reviewers a Plenty</h4>
                  <p className="plenty tagp">
                    One of the biggest hurdles societies face is finding
                    reliable and efficient Peer Reviewers. It is a common pain
                    point. Say goodbye to that worry because we have a vast
                    network of expert reviewers ready to contribute their
                    expertise to your research.
                  </p>
                  <p className="peerreviewers tagp">
                    What’s more, we offer both{" "}
                    <strong>Pre Publication Double Anonymous</strong> , and{" "}
                    <strong>Post Publication Open Review </strong> subscribing
                    to the opportunities promoted by Open Science.
                  </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 convinced-img-wrp convinced-col-4">
              <div className="convinced-left-text convinced-text">
                <div className="convinced-text-col">
                <div className="leftSpace04">
                  <h4 className="tagh4">Exercise Full Control</h4>
                  <p className="control tagp">
                    We firmly believe in putting you in the driver’ seat. With
                    us, you have full control. You can effortlessly manage
                    submissions, handpick, and oversee your editorial board, and
                    experience lightning-fast publishing speed and turnaround
                    time.
                  </p>
                  </div>
                </div>
              </div>
              <div className="convinced-img-col">
                <img src="assets/images/convinced-4.png" className="tagimg" alt="Exercise Full Control" />
              </div>
            </div>
          </div>
          <div className="ag_heading_center_wrap community-col sell-btm-form mt-5 mb-5" id={`viewScroll${"connectNow"}`}>
          
            <h2 className="tagh2">
              Please fill out this simple form and take the first step in{" "}
              <br/>joining hands with one of the fastestgrowing <br/>open access
              journal publishing community.
            </h2>
          </div>

          <JoinPublishingCommunityForm  />
        </div>

        <div className="container">
          <div className="mt-5 mb-5 text-center">
            <p className="pera-text tagp">
              For more questions on our processes, policies and other aspects,
              please refer to our <Link  to="/faq" target="_blank"><strong className='underline'>FAQ section.</strong></Link>
            </p>
          </div>
        </div>
      </main>
      {/* NewsLetter Start */}
      <NewsLetter />
      <AakashgangaFooter />
      </div>
      {/* Footer Redline Start */}
    </>
  );
}

export default SellPageForeign;
