import NewsLetter from "../components/global/NewsLetter";
import {useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import AakashgangaHeader from "../components/global/HeaderComponent";
import AakashgangaFooter from "../components/global/FooterComponent";
import MobileModalReg from "../components/Modal/MobileMobleReg";
import { Helmet } from 'react-helmet-async';
function PeerReviewModels() {
	const navigate = useNavigate();
	const [mobileAlertReg, setMobileAlertReg] = useState(false);
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
	return (
		<>
		<Helmet>
        <title>Aakashganga Open Access: Peer Reviewed Journals</title>
        <meta
          name="description"
          content="Explore peer-reviewed articles on our platform. Choose between pre-publishing double anonymous peer review or post-publishing open peer review for your research papers."
        />
		<meta property="og:title" content="Aakashganga Open Access: Peer Reviewed Journals" data-react-helmet="true" />
          <meta property="og:description" content="Explore peer-reviewed articles on our platform. Choose between pre-publishing double anonymous peer review or post-publishing open peer review for your research papers." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/peer-review-models" data-react-helmet="true" />
		  <link rel="canonical" href="https://www.aakashgangaopen.in/peer-review-models" data-react-helmet="true" />
		  <meta name="robots" content="index, follow" />
      </Helmet>
		<div className="tagbody">
		<AakashgangaHeader />
			<h1 className="seo-related-tag">
				Peer Review Models
			</h1>
			<main className='main'>
				<div className='banner-section'>
					<div className='page-title'>
						<h1 className='title tagh1'>
							Peer Rev<span>i</span>ew Models
						</h1>
					</div>
				</div>

				<div className='content-area'>
					<div className='container'>
						<p className='pera-text tagp mt-3'>
							Thank you for considering the Aakashganga Open
							Access Platform for publishing your research paper.
							As part of our commitment to maintaining
							high-quality scholarly content, we offer two kinds
							to Peer Reviews, to suit your research preference.
						</p>
					<div className="peerUl">
						<ul className='tagul '>
							<li className="section tagli">
								<strong>
									Pre Publishing Double Anonymous Peer Review
									–
								</strong>{" "}
								In this kind of Peer Review, suitable Peer
								Reviewers, selected by our system, will be
								invited to review, and present their views
								before the article is published.
							</li>

							<li className="efforts tagli">
								<strong>
									Post Publishing Open Peer Review –
								</strong>{" "}
								In this system, the Peer Review is done directly
								on the platform. No reviewers are invited
								specifically for this purpose and the review
								process remains open as long as the article is
								hosted on Aakashganga. By default, all articles
								will go for this sort of review.
							</li>
						</ul>
						</div>
						<p className='pera-text italic efforts tagp'>
							<span className='red-text bold600'>Please Note:</span> that
							the choice between Pre Publishing Double Anonymous
							Peer Review and Post Publishing Open Peer Review
							depends on a researcher’s goals, preferences, and
							the nature of the research. Both approaches have
							their own advantages and considerations, and
							researchers should carefully evaluate which peer
							review process aligns best with their objectives and
							values.
						</p>

						<p className='pera-text tagp'>
							For benefits of Peer Review, please read:{" "}
							<Link className="colorBlack" to="/peer-reviewer-guidelines" target="_blank"><strong className='underline'>
								Reviewer Guidelines
							</strong></Link>
						</p>

						<div className='register-button'>
							<button onClick={(e) =>isMobile ? setMobileAlertReg(true): handleRedirect(e, "/registration")}>Register as a Peer Reviewer</button>
						</div>

						<p className='pera-text tagp'>
							We deeply appreciate the efforts of our Peer
							Reviewers and value their role in shaping the future
							of research. As a thank you gesture, we provide a
							small honorarium in the form of <strong>Mudras</strong> (INR) for every Peer Review
							undertaken, which is deposited in the{" "}
							<strong>Mudra Kosh</strong> (Wallet) in your
							respective account. You may use these Mudras to
							write a new research article, or update an existing
							article on the platform.
						</p>

						<div className="peerManage">
						<h2 className='large-title tagh2'>
							Pre Publishing Double Anonymous Peer Rev
							<span>i</span>ew
						</h2>

						<h2 className='small-title tagh2'>
							Anonymity in Peer Review
						</h2>

						<p className='pera-text tagp'>
							To ensure fairness and impartiality, our platform
							follows a double-anonymous review model. This means
							that both the reviewers and authors remain anonymous
							throughout the review process. Anonymity helps
							eliminate biases that could arise based on the
							author’s identities, affiliations, or previous work.
							It allows for an objective evaluation of the
							research paper solely on its scientific merits.
						</p>

						<h2 className='small-title tagh2'>
							Article Processing Charge (APC)
						</h2>

						<p className='pera-text tagp'>
							As an open access platform, we follow the{" "}
							<strong>Gold Open Access Model</strong>, in which
							scholarly articles are freely and immediately
							available to readers without any subscription
							barriers or paywalls. All articles published on the
							Aakashganga platform will be made immediately and
							openly accessible on the website under the{" "}
							<a href='https://creativecommons.org/licenses/by-nc-sa/4.0/'
								target='blank' className='taga colorBlack'
							>
								<strong className='underline'>
									CC-BY-NC-SA
								</strong>
							</a>{" "}
							license, allowing anyone with internet access to
							read, download, share, and use the research findings{" "}
							<strong>
								for personal and non-commercial use.
							</strong>
						</p>

						<p className='pera-text tagp'>
							There is therefore{" "}
							<strong>no embargo period,</strong> and authors
							retain the <strong>copyright of their work</strong> when
							publishing under the Gold Open Access model.
						</p>

						<p className='pera-text  tagp'>
							The APC ensures that we can maintain the integrity
							of our peer review process, enhance the visibility
							of your published work, and provide unrestricted
							access to your research for the global scientific
							community.
						</p>
						</div>
						<h2 className='large-title tagh2'>
						Post Publishing Open Peer Rev
							<span>i</span>ew
						</h2>

						<p className='pera-text tagp'>
							If you are a researcher who does not want to opt for{" "}
							<strong>
								Pre Publishing Double Anonymous Peer Review,
							</strong> we offer a second model of Peer Review called{" "}
							<strong>Post Publishing Open Peer Review,</strong>{" "}
							which is, which is an innovative approach that
							allows for an open exchange of feedback, comments,
							and discussions from the scientific community after
							the publication of an article. This methodology aims
							to enhance the quality and impact of research by
							encouraging broader engagement and collaboration
							among researchers.
						</p>

						<div className='table-content'>
							<table>
								<thead>
									<tr>
										<th className='pl-5'>
											Pre Publishing Double Anonymous Peer
											Review
										</th>

										<th className='pl-5'>
											Post Publishing Open Peer Review
										</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td>
											Occurs before the publication of the
											article
										</td>

										<td>
											Occurs after the publication of the
											article
										</td>
									</tr>

									<tr>
										<td className="table-content td.size">
											Reviewers’ identities and authors’
											identities are concealed
										</td>

										<td>
											Reviewers’ dentities and authors’
											identities are known
										</td>
									</tr>

									<tr>
										<td>
											Feedback is provided to authors
											privately
										</td>

										<td>
											Feedback is provided publicly and
											openly visible to all readers
										</td>
									</tr>

									<tr>
										<td>
											Limited opportunity for direct
											author-reviewer interaction
										</td>

										<td>
											Encourages direct author-reviewer
											and reader interaction
										</td>
									</tr>

									<tr>
										<td>
											Authors may need to wait longer for
											publication as revisions are made
										</td>

										<td>
											Rapid publication as articles are
											published first and then reviewed
										</td>
									</tr>

									<tr>
										<td>
											Focuses on evaluating research
											quality, methodology, and validity
										</td>

										<td>
											Focuses on evaluating research
											quality and encouraging discussions
											and collaborations
										</td>
									</tr>

									<tr>
										<td>
											Emphasizes anonymity to reduce
											potential bias
										</td>

										<td>
											Emphasizes transparency and
											constructive criticism
										</td>
									</tr>

									<tr>
										<td>
											Final decision on acceptance or
											rejection is made before publication
										</td>

										<td>
											Iterative process where articles can
											be revised based on feedback
										</td>
									</tr>

									<tr>
										<td>
											Authors do not have the opportunity
											to respond or address reviewers’
											comments
										</td>

										<td>
											Authors can actively respond to
											Reviewers’s comments and clarify any
											questions
										</td>
									</tr>

									<tr>
										<td>
											Prioritizes confidentiality and
											protecting authors’ work from
											premature exposure
										</td>

										<td>
											Prioritizes early visibility and
											engagement for research articles
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<p className='pera-text text-center tagp'>
							For more questions on our processes, policies and
							other aspects, please refer to our <Link  to="/faq" target="_blank"><strong className='underline '>FAQ section.</strong></Link>
						</p>
					</div>
				</div>
			</main>
			{/* NewsLetter Start */}
			<NewsLetter />
			{/* Footer Redline Start */}
		<AakashgangaFooter />
		<MobileModalReg mobileAlertReg={mobileAlertReg}
    setMobileAlertReg={setMobileAlertReg} />
		</div>
		</>
	);
}

export default PeerReviewModels;
