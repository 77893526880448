import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import demoImg from '../../assets/assets_html/images/oppor-1.png'
import SubmitArticleModal from '../Modal/SubmitArticleModal';

const opportunityData = [
	{
		heading: "Authors & Research Scholars",
		subHeading: "Publish with Confidence",
		description:
			"A platform built by researchers, for researchers. Transparent, fast and always affordable. Find out more about us.",
		imgUrl: "url(assets/images/oppor-1.png)",
		details: [{
			name: "Find A Journal",
			link: "/about-megajournals"
		},
		{
			name: "Publish Your Article",
			link: ""
		},
		{
			name: "Author Guidelines",
			link: "/author-submission-guidelines"
		},
		{
			name: "Article Processing Charges",
			link: "/apc"
		},
		{
			name: "Resource Centre",
			link: "https://vikramshilaedu.in/resourcecentre"
		},
		{
			name: "Publish With Us",
			link: "/publish-with-us"
		}],
	},
	{
		heading: "Editors & Reviewers",
		subHeading: "Sculpt the Future",
		description:
			"Want to know how we publish? Here’s the lowdown. Come join us as a Publishing Editor and/or Peer Reviewer.",
		imgUrl: "url(assets/images/oppor-2.png)",
		details: [{
			name: "How We Publish",
			link: "/how-we-publish"
		},
		{
			name: "Peer Review Models",
			link: "/peer-review-models"
		},
		{
			name: "Reviewer Guidelines",
			link: "/peer-reviewer-guidelines"
		},
		{
			name: "Publishing editor guidelines",
			link: "/publishing-editor-roles-and-responsibilities"
		},
		{
			name: "Join Us As A Publishing Editor",
			link: "/registration"
		},
		{
			name: "Join Us As A Peer Reviewer",
			link: "/registration"
		},{
			name: "Publish With Us",
			link: "/publish-with-us"
		}]
	},
	{
		heading: "Universities, Societies, Institutes",
		subHeading: "Fuel Scholarly Research",
		description:
			"Want to launch your own journal? Find out how we can be your first and only choice. Simple, Affordable, Global.",
		imgUrl: "url(assets/images/oppor-3.png)",
		details: [{
			name: "Partnerships",
			link: "/domestic-societies-partnerships"
		},
		{
			name: "Foreign University, Society, Institute",
			link: "/foreign-societies-partnerships"
		},
		{
			name: "Connect Now",
			link: "/domestic-societies-partnerships"
		},
		{
			name: "Publish Conference Papers",
			link: "/conference-papers"
		},
		{
			name: "Publish With Us",
			link: "/publish-with-us"
		}],
	},
];
const Opportunities = () => {
	const token = localStorage.getItem("usertoken");
	const navigate = useNavigate();
	const [opportunityList, setOpportunityList] = useState(opportunityData)
	const [uploadArticle, setUploadArticle] = useState(false);
	const readMoretoggle = (opportunity) => {
		const updated = opportunityList.map(item => {
			if (item.heading === opportunity.heading) {
				return ({ ...item, showMore: !opportunity.showMore })
			} else {
				return item
			}
		})
		setOpportunityList(updated)
	}

	return opportunityList.map((opportunity, index) => (
		<div
			key={opportunity.heading}
			className='col-xl-4 col-lg-6 col-sm-6 opportunity-card-container blog-type'
		>
			<div
				className='ag_opportunities_box aos-init'
				data-aos='zoom-in-up'
			>
				<div className={`cimg ${opportunity?.showMore === true ? 'hide' : ''}`}
					style={{ backgroundImage: opportunity.imgUrl }}
				><img src={demoImg} alt="#" /></div>
				<h3 className={opportunity?.showMore ? 'cactive redHeader show' : 'cactive redHeader '}>{opportunity.heading}</h3>
				<h4 className={opportunity?.showMore ? 'chide hide tagh4' : 'chide tagh4'}>{opportunity.subHeading}</h4>
				<p className={index === 0 ? 'cactive-descrip tagp' : 'cactive-descrip tagp'}>{opportunity.description}</p>
				<ul className={opportunity?.showMore ? 'cactive ag_oppbullets show tagul' : 'cactive ag_oppbullets tagul'}>
					{opportunity.details.map((item) => (
						<li className="tagli" key={item}><Link to={item?.name === "Publish Your Article" && !token ? "/login" : item.link} onClick={() => (item?.name === "Publish Your Article" && token) && setUploadArticle(true)} target={item?.name === "Join Us As A Publishing Editor" || item?.name === "Join Us As A Peer Reviewer" || item?.name === "Publish Your Article" ? "" : "_blank"}>{item.name}</Link></li>
					))}
				</ul>
				<a href='javascript:;' className='ag_toggle read_more'
					onClick={() => readMoretoggle(opportunity)}
				>
					{!opportunity?.showMore ? `Read More` : `< Back`}
				</a>
			</div>
			<SubmitArticleModal
        		uploadArticle={uploadArticle}
        		setUploadArticle={setUploadArticle}
      		/>
		</div>
		
	));
};

export default Opportunities;
